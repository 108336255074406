import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function FirstNameStep({ nextStep, previousStep, updateFormData, formData }) {
  const [firstName, setFirstName] = useState(formData.firstName || '');
  const navigate = useNavigate();

  // Handle form submission and move to the next step
  const handleNext = (e) => {
    e.preventDefault();
    if (firstName.trim() === '') {
      alert('Please enter your first name.');
      return;
    }
    updateFormData({ firstName });
    nextStep();
  };

  // Handle back navigation
  const handleBack = () => {
    previousStep ? previousStep() : navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      {/* Back Button positioned absolutely to the left */}
      <button 
        onClick={handleBack} 
        className="absolute top-4 left-4 text-gray-500 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>
      {/* Content Section */}
      <div className="flex flex-col items-center text-center w-full max-w-xs mt-16"> {/* Added margin-top to avoid overlap */}
        <h2 className="text-2xl font-semibold mb-10">What's your first name?</h2>

        <form onSubmit={handleNext} className="w-full">
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Enter your first name"
            className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-black mb-4 bg-transparent"
          />
          <p className="text-sm text-gray-500 mb-8">
            This is how it’ll appear on your profile. <br />
            <b>You won't be able to change this.</b>
          </p>
        </form>
          <button
            onClick={handleNext}
            className="px-6 py-3 bg-black text-white rounded-full w-full"
          >
          Next
        </button>
      </div>
    </div>
  );
}

export default FirstNameStep;
