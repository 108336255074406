import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PicturesStep({ previousStep, updateFormData, formData, nextStep }) {
  const [pictures, setPictures] = useState(formData.pictures || null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle file input change for a single picture and upload it to the server
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!formData.email) {
        setError('Email is missing. Please go back and enter your email.');
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('pictures', file);
      formDataToSend.append('email', formData.email); // Ensure email is included in the request

      fetch('https://infost490f2408.soisweb.uwm.edu/uploadPicture.php', {
        method: 'POST',
        body: formDataToSend,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (data.success) {
            setPictures(data.pictureUrl); // Set the picture URL
            updateFormData({ pictures: data.pictureUrl }); // Update formData with the URL
            setError(''); // Clear any previous errors
          } else {
            setError(data.message || 'Failed to upload picture');
          }
        })
        .catch((err) => {
          console.error('Error uploading picture:', err);
          setError('An error occurred while uploading the picture.');
        });
    }
  };

  const handleNext = () => {
    if (!pictures) {
      setError('Please upload a picture.');
      return;
    }
    setError(''); // Clear any previous errors
    nextStep(); // Proceed to the next step
  };

  const handleBack = () => {
    previousStep ? previousStep() : navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      <button onClick={handleBack} className="absolute top-4 left-4 text-gray-500 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>

      <h2 className="text-2xl font-semibold mt-16 mb-10">Add a picture of yourself</h2>

      <div className="relative w-40 h-40 border-2 border-dashed border-gray-300 flex items-center justify-center mb-8">
        {pictures ? (
          <img src={pictures} alt="Profile preview" className="w-full h-full object-cover rounded-md" />
        ) : (
          <>
            <input type="file" accept="image/*" className="absolute inset-0 opacity-0 cursor-pointer" onChange={handleFileChange} />
            <span className="text-gray-400 text-4xl">+</span>
          </>
        )}
      </div>

      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

      <div className="mt-8 w-full max-w-xs">
        <button onClick={handleNext} className="px-6 py-3 bg-black text-white rounded-full w-full">
          Next
        </button>
      </div>
    </div>
  );
}

export default PicturesStep;
