import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function GenderStep({ nextStep, previousStep, updateFormData, formData }) {
  const [gender, setGender] = useState(formData.gender || '');
  const [customGender, setCustomGender] = useState(formData.customGender || '');
  const navigate = useNavigate();

  // Handle gender selection
  const handleSelectGender = (selectedGender) => {
    setGender(selectedGender);
    if (selectedGender !== 'More') {
      setCustomGender(''); // Clear custom gender if "More" is deselected
    }
    updateFormData({ gender: selectedGender });
  };

  // Handle next button click
  const handleNext = (e) => {
    e.preventDefault();
    if (!gender) {
      alert('Please select or specify your gender.');
      return;
    }
    // Include custom gender if provided
    updateFormData({ gender, customGender });
    nextStep();
  };

  // Handle back button
  const handleBack = () => {
    previousStep ? previousStep() : navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      {/* Back Button */}
      <button 
        onClick={handleBack} 
        className="absolute top-4 left-4 text-gray-500 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>

      {/* Content Section */}
      <div className="flex flex-col items-center text-center w-full max-w-xs mt-16">
        <h2 className="text-2xl font-semibold mb-10">What's your gender?</h2>

        {/* Gender Options */}
        <div className="space-y-4 w-full">
          <button
            onClick={() => handleSelectGender('Woman')}
            className={`w-full py-3 border border-gray-300 rounded-full ${
              gender === 'Woman' ? 'bg-gray-200' : 'bg-transparent'
            }`}
          >
            Woman
          </button>
          <button
            onClick={() => handleSelectGender('Man')}
            className={`w-full py-3 border border-gray-300 rounded-full ${
              gender === 'Man' ? 'bg-gray-200' : 'bg-transparent'
            }`}
          >
            Man
          </button>
          <button
            onClick={() => handleSelectGender('More')}
            className={`w-full py-3 border border-gray-300 rounded-full flex items-center justify-center ${
              gender === 'More' ? 'bg-gray-200' : 'bg-transparent'
            }`}
          >
            <span>More</span>
            <span className="ml-2 text-gray-500">&gt;</span> {/* Arrow for "More" */}
          </button>

          {/* Custom Gender Input - Visible only if "More" is selected */}
          {gender === 'More' && (
            <input
              type="text"
              value={customGender}
              onChange={(e) => setCustomGender(e.target.value)}
              placeholder="Please specify"
              className="w-full p-2 border-b-2 border-gray-300 text-center focus:outline-none focus:border-black bg-yellow-50 text-gray-700 placeholder-gray-400 mt-4"
            />
          )}
          <button
          onClick={handleNext}
          className="px-6 py-3 bg-black text-white rounded-full w-full"
        >
          Next
        </button>
        </div>
      </div>
    </div>
  );
}

export default GenderStep;
