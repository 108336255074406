import React, { useState } from 'react';
import EmailStep from './steps/EmailStep';
import FirstNameStep from './steps/FirstNameStep';
import BirthdayStep from './steps/BirthdayStep';
import GenderStep from './steps/GenderStep';
import PasswordStep from './steps/PasswordStep';
import PicturesStep from './steps/PicturesStep';
import ConfirmationStep from './steps/ConfirmationStep';
import AccountCreatedStep from './steps/AccountCreatedStep';

function CreateAccount() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    birthday: '',
    gender: '',
    password: '',
    pictures: '',
  });

  const updateFormData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const nextStep = () => setStep(step + 1);
  const previousStep = () => setStep(step - 1);
  const submitForm = () => setStep(step + 1); // Moves to AccountCreatedStep

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      {step === 1 && (
        <EmailStep
          nextStep={nextStep}
          updateFormData={updateFormData}
          formData={formData}
        />
      )}
      {step === 2 && (
        <FirstNameStep
          nextStep={nextStep}
          previousStep={previousStep}
          updateFormData={updateFormData}
          formData={formData}
        />
      )}
      {step === 3 && (
        <BirthdayStep
          nextStep={nextStep}
          previousStep={previousStep}
          updateFormData={updateFormData}
          formData={formData}
        />
      )}
      {step === 4 && (
        <GenderStep
          nextStep={nextStep}
          previousStep={previousStep}
          updateFormData={updateFormData}
          formData={formData}
        />
      )}
      {step === 5 && (
        <PasswordStep
          nextStep={nextStep}
          previousStep={previousStep}
          updateFormData={updateFormData}
          formData={formData}
        />
      )}
      {step === 6 && (
        <PicturesStep
          nextStep={nextStep} // Next goes to ConfirmationStep now
          previousStep={previousStep}
          updateFormData={updateFormData}
          formData={formData}
        />
      )}
      {step === 7 && (
        <ConfirmationStep
          previousStep={previousStep} // User can go back to PicturesStep
          formData={formData}
          submitForm={submitForm} // Submits form and goes to Account Created
        />
      )}
      {step === 8 && <AccountCreatedStep />}
    </div>
  );
}

export default CreateAccount;
