import React from 'react';
import { useNavigate } from 'react-router-dom';

function AccountCreatedStep() {
  const navigate = useNavigate();

  // Handle Continue button click to navigate to a placeholder dashboard
  const handleContinue = () => {
    navigate('/dashboard'); // Change this path if needed
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      <h2 className="text-2xl font-semibold mb-8">Account Created!</h2>
      <p className="mb-8">
        Your account has been successfully created. You are ready to continue exploring.
      </p>

      {/* Centered Continue Button */}
      <div className="w-full max-w-xs">
        <button
          onClick={handleContinue}
          className="px-6 py-3 bg-black text-white rounded-full w-full"
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default AccountCreatedStep;
