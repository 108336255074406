// src/api/eventService.js
import MarketImage from '../mpm.jpg';
import CheeseCurds from '../curds.jpg';
import RiverWalk from '../riverwalk.jpg';
// Static event data to simulate API response
const events = [
    {
      id: 1,
      title: "Take a selfie at the Milwaukee Public Market",
      image: MarketImage,
      location: "Milwaukee, WI",
      description: "Capture your experience at the iconic Milwaukee Public Market."
    },
    {
      id: 2,
      title: "Cheese curds taste test",
      image: CheeseCurds,
      location: "Milwaukee, WI",
      description: "Indulge in Milwaukee's famous cheese curds and rate your favorites!"
    },
    {
      id: 3,
      title: "Walk the River Walk backwards",
      image: RiverWalk,
      location: "Milwaukee, WI",
      description: "Take a unique stroll through Milwaukee's scenic River Walk - backwards!"
    },
  ];
  
  // Function to fetch events - simulates API request
  export function getEvents() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(events); // Simulate delay and resolve with static data
      }, 500); // Simulated network delay of 500ms
    });
  }
  
  // Function to get event details by ID
  export function getEventById(id) {
    return new Promise((resolve) => {
      const event = events.find((event) => event.id === parseInt(id));
      setTimeout(() => {
        resolve(event); // Resolve with the specific event data
      }, 500);
    });
  }
  