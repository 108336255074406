import React, { useState, useEffect } from 'react';

function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPicture, setNewPicture] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');

    if (userEmail) {
      fetch(`https://infost490f2408.soisweb.uwm.edu/getUserData.php?email=${encodeURIComponent(userEmail)}`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setUser(data.data);
          } else {
            setError(data.message || 'Failed to fetch user data');
          }
          setLoading(false);
        })
        .catch(err => {
          console.error('Error fetching user data:', err);
          setError('An error occurred while fetching user data.');
          setLoading(false);
        });
    } else {
      setError('User not authenticated.');
      setLoading(false);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewPicture(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('pictures', file);
      formData.append('email', localStorage.getItem('userEmail')); // Attach user email

      fetch('https://infost490f2408.soisweb.uwm.edu/uploadPicture.php', {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setNewPicture(data.pictureUrl);
          } else {
            setError('Failed to upload new picture');
          }
        })
        .catch(err => {
          console.error('Error uploading new picture:', err);
          setError('An error occurred while uploading the picture.');
        });
    }
  };

  const handleSavePicture = () => {
    if (newPicture) {
      const userEmail = localStorage.getItem('userEmail');
      fetch(`https://infost490f2408.soisweb.uwm.edu/updateProfileImage.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail, picture: newPicture }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setUser((prev) => ({ ...prev, profile_picture: newPicture }));
            handleCloseModal();
          } else {
            setError('Failed to save new picture');
          }
        })
        .catch(err => {
          console.error('Error updating new picture:', err);
          setError('An error occurred while saving the new picture.');
        });
    }
  };

  const handleDeleteAccount = () => {
    const userEmail = localStorage.getItem('userEmail');
    fetch('https://infost490f2408.soisweb.uwm.edu/deleteUser.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: userEmail }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Clear local storage and redirect to login or home page
          localStorage.removeItem('userEmail');
          window.location.href = '/login';
        } else {
          setError('Failed to delete account');
        }
      })
      .catch(err => {
        console.error('Error deleting account:', err);
        setError('An error occurred while deleting the account.');
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-yellow-50 p-6 flex flex-col items-center">
      {user && (
        <>
          {/* Name and Age at the Top */}
          <h2 className="text-3xl font-semibold mb-2 text-center">
            {user.name}, {user.age}
          </h2>

          {/* Profile Image */}
          <img
            src={user.profile_picture}
            alt="Profile"
            className="w-64 h-64 rounded-lg object-cover mb-4"
          />

          {/* Buttons for Editing Profile and Deleting Account */}
          <div className="flex space-x-4 mb-4">
            <button onClick={handleOpenModal} className="px-4 py-2 bg-gray-200 text-black rounded-lg">
              Edit profile image
            </button>
            <button onClick={() => setShowDeleteModal(true)} className="px-4 py-2 bg-red-500 text-white rounded-lg">
              Delete Account
            </button>
          </div>

          {/* Recently active and verified experiences */}
          <div className="flex space-x-4 mb-4">
            <span className="px-3 py-1 bg-yellow-100 rounded-full text-sm">Recently active</span>
            <span className="px-3 py-1 bg-yellow-100 rounded-full text-sm">2 verified experiences</span>
          </div>

          {/* Location */}
          <p className="text-sm text-gray-600 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
            </svg>
            Milwaukee, WI
          </p>
        </>
      )}

      {/* Modal for Picture Update */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 flex flex-col items-center">
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-black"
            >
              &times;
            </button>
            <h3 className="text-lg font-semibold mb-4 text-center">Update your profile picture</h3>
            <div className="flex justify-center items-center w-40 h-40 border-2 border-dashed border-gray-300 mb-4">
              {newPicture ? (
                <img
                  src={newPicture}
                  alt="New profile preview"
                  className="w-full h-full object-cover rounded-md"
                />
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    onChange={handleFileChange}
                  />
                  <span className="text-gray-400 text-4xl">+</span>
                </>
              )}
            </div>
            <button
              onClick={handleSavePicture}
              className="w-full px-4 py-2 bg-black text-white rounded-full mb-2"
              disabled={!newPicture}
            >
              Save profile picture
            </button>
            <button
              onClick={handleCloseModal}
              className="w-full px-4 py-2 bg-gray-200 text-black rounded-full"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Modal for Account Deletion */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80 justify-center items-center text-center">
            <h3 className="text-lg font-semibold mb-4">Confirm Account Deletion</h3>
            <p className="mb-4">Are you sure you want to delete your account? This action is irreversible.</p>
            <button onClick={handleDeleteAccount} className="w-full px-4 py-2 bg-red-500 text-white rounded-full mb-2">
              Yes, delete my account
            </button>
            <button onClick={() => setShowDeleteModal(false)} className="w-full px-4 py-2 bg-gray-200 text-black rounded-full">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
