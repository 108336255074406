import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BirthdayStep({ nextStep, previousStep, updateFormData, formData }) {
  const [birthday, setBirthday] = useState(formData.birthday || { day: '', month: '', year: '' });
  const navigate = useNavigate();

  // Function to format the date to YYYY-MM-DD for backend submission
  const formatBirthdayForBackend = (birthday) => {
    const { day, month, year } = birthday;
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  // Handle form submission and move to the next step
  const handleNext = (e) => {
    e.preventDefault();
    const { day, month, year } = birthday;

    if (!day || !month || !year) {
      alert('Please enter a complete date of birth.');
      return;
    }

    // Format the birthday for the backend
    const formattedBirthday = formatBirthdayForBackend(birthday);

    updateFormData({ birthday: formattedBirthday });
    nextStep();
  };

  // Handle back navigation
  const handleBack = () => {
    previousStep ? previousStep() : navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      {/* Back Button positioned absolutely to the left */}
      <button 
        onClick={handleBack} 
        className="absolute top-4 left-4 text-gray-500 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>

      {/* Content Section */}
      <div className="flex flex-col items-center text-center w-full max-w-xs mt-16">
        <h2 className="text-2xl font-semibold mb-10">What's your birthday?</h2>

        <form onSubmit={handleNext} className="w-full">
          {/* Date Input Fields */}
          <div className="flex justify-center space-x-4 mb-4">
            <input
              type="text"
              value={birthday.month}
              onChange={(e) => setBirthday({ ...birthday, month: e.target.value })}
              placeholder="MM"
              maxLength="2"
              className="w-16 p-2 border-b-2 border-gray-300 text-center focus:outline-none focus:border-black bg-transparent text-gray-700 placeholder-gray-400"
            />

            <input
              type="text"
              value={birthday.day}
              onChange={(e) => setBirthday({ ...birthday, day: e.target.value })}
              placeholder="DD"
              maxLength="2"
              className="w-16 p-2 border-b-2 border-gray-300 text-center focus:outline-none focus:border-black bg-transparent text-gray-700 placeholder-gray-400"
            />
            <input
              type="text"
              value={birthday.year}
              onChange={(e) => setBirthday({ ...birthday, year: e.target.value })}
              placeholder="YYYY"
              maxLength="4"
              className="w-24 p-2 border-b-2 border-gray-300 text-center focus:outline-none focus:border-black bg-transparent text-gray-700 placeholder-gray-400"
            />
          </div>

          <p className="text-sm text-gray-500 mb-8">
            Your profile shows your age, not your date of birth.
          </p>

          {/* Next Button */}
          <button
            type="submit"
            className="px-6 py-3 bg-black text-white rounded-full w-full"
          >
            Next
          </button>
        </form>
      </div>
    </div>
  );
}

export default BirthdayStep;
