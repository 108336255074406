import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PasswordStep({ nextStep, previousStep, updateFormData, formData }) {
  const [password, setPassword] = useState(formData.password || '');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle next button click
  const handleNext = () => {
    if (!password || password.length < 8) {
      setError('Password must be at least 8 characters.');
    } else if (password !== confirmPassword) {
      setError('Passwords do not match.');
    } else {
      updateFormData({ password });
      nextStep(); // Go to the next step
    }
  };

  // Handle back button
  const handleBack = () => {
    previousStep ? previousStep() : navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full">
      {/* Back Button */}
      <button 
        onClick={handleBack} 
        className="absolute top-4 left-4 text-gray-500 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>

      {/* Title */}
      <h2 className="text-2xl font-semibold mt-12 mb-8">Set a Password</h2>

      {/* Password Input Fields */}
      <div className="w-full max-w-md space-y-4 mb-6">
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
          placeholder="Enter password"
          className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-black bg-transparent"
        />

        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setError('');
          }}
          placeholder="Confirm password"
          className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-black bg-transparent"
        />
      </div>

      {/* Error Message */}
      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

      {/* Next Button */}
      <button
        onClick={handleNext}
        className="px-6 py-3 bg-black text-white rounded-full w-full max-w-md"
      >
        Next
      </button>
    </div>
  );
}

export default PasswordStep;
