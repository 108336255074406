// src/messages/Messages.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Messages() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-yellow-50 p-6">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-2xl font-semibold mb-4">Work in Progress</h2>
        <p className="mb-6">The messaging feature is currently under development. Please check back later!</p>
        <button
          onClick={() => navigate('/dashboard')}
          className="px-6 py-2 bg-black text-white rounded-full"
        >
          Go Back
        </button>
      </div>
    </div>
  );
}

export default Messages;
