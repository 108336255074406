// src/SignIn.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SignIn({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle login form submission
  const handleLogin = async () => {
    try {
      const response = await fetch('https://infost490f2408.soisweb.uwm.edu/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const result = await response.json();
      if (result.success) {
        // Store the user's email in localStorage after a successful login
        localStorage.setItem('userEmail', email);

        // Call the onLogin function passed as a prop to set authentication state in App.js
        onLogin();
        // Navigate to the dashboard on successful login
        navigate('/dashboard');
      } else {
        setError(result.message || 'Invalid email or password');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full">
      {/* Back Button */}
      <button onClick={() => navigate(-1)} className="absolute top-4 left-4 text-gray-500 flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>

      {/* Title */}
      <h2 className="text-2xl font-semibold mt-12 mb-8">Sign In</h2>

      {/* Email and Password Input Fields */}
      <div className="w-full max-w-md space-y-4 mb-6">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-black bg-transparent"
        />

        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-black bg-transparent"
        />
      </div>

      {/* Display Error Message */}
      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

      {/* Sign In Button */}
      <button
        onClick={handleLogin}
        className="px-6 py-3 bg-black text-white rounded-full w-full max-w-md"
      >
        Sign In
      </button>
    </div>
  );
}

export default SignIn;
