import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ConfirmationStep({ previousStep, formData, submitForm }) {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Handle the final form submission
  const handleFinalSubmit = async () => {
    try {
      const response = await fetch('https://infost490f2408.soisweb.uwm.edu/register.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData), // Submit all form data to backend
      });

      const result = await response.json();
      if (result.success) {
        submitForm(); // This will take the user to the account created page
      } else {
        setError('Error: ' + result.message);
      }
    } catch (err) {
      console.error('Form submission error:', err);
      setError('Failed to submit. Please try again.');
    }
  };

  // Handle back navigation
  const handleBack = () => {
    previousStep ? previousStep() : navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      {/* Back Button positioned absolutely to the left */}
      <button 
        onClick={handleBack} 
        className="absolute top-4 left-4 text-gray-500 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>

      <h2 className="text-2xl font-semibold mb-8 mt-16">You're all set!</h2>
      <p className="mb-4">Click below to create your account with the information provided.</p>

      {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

      {/* Centered Create Account Button */}
      <div className="w-full max-w-xs">
        <button
          onClick={handleFinalSubmit}
          className="px-6 py-3 bg-black text-white rounded-full w-full"
        >
          Create Account
        </button>
      </div>
    </div>
  );
}

export default ConfirmationStep;
