import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function EmailStep({ nextStep, updateFormData, formData }) {
  const [email, setEmail] = useState(formData.email || '');
  const navigate = useNavigate();

  // Handle form submission to go to the next step
  const handleNext = (e) => {
    e.preventDefault();
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }
    updateFormData({ email });
    nextStep();
  };

  // Handle back navigation to the login page
  const handleBack = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex flex-col justify-between items-center bg-gradient-to-b from-white to-yellow-50 p-6 w-full relative">
      {/* Back button at the top-left */}
      <button onClick={handleBack} className="absolute top-4 left-4 text-gray-500 flex items-center">
        {/* SVG Icon */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
        Back
      </button>
      
      <div className="flex flex-col items-center text-center w-full max-w-xs mt-16">
        <h2 className="text-2xl font-semibold mb-10">What's your email?</h2>

        <form onSubmit={handleNext}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="w-full p-2 border-b-2 border-gray-300 focus:outline-none focus:border-black mb-4 bg-transparent"
          />
          <button type="submit" className="px-6 py-3 bg-black text-white rounded-full w-full">
            Next
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailStep;
