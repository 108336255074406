import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './Welcome';
import Loading from './Loading';
import SignIn from './SignIn';
import CreateAccount from './CreateAccount';
import Dashboard from './dashboard/Dashboard';
import Messages from './messages/Messages';
import Profile from './profile/Profile';
import EventDetail from './dashboard/EventDetails';
import NavBar from './components/NavBar'; // Import NavBar
import './tailwind.css';

/**
 * Main App component that manages routing and authentication state.
 * Displays a loading screen initially, then renders the app with
 * conditional rendering based on authentication.
 */
function App() {
  const [isLoading, setIsLoading] = useState(true); // Manages loading state
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Manages authentication state

  useEffect(() => {
    // Simulate loading state
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  //  login function to set user as authenticated
  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      {/* Show loading screen until isLoading is false */}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Welcome />} />
            <Route path="/sign-in" element={<SignIn onLogin={handleLogin} />} />
            <Route path="/create-account" element={<CreateAccount />} />

            {/* Protected Routes */}
            <Route
              path="/dashboard"
              element={isAuthenticated ? <Dashboard /> : <SignIn onLogin={handleLogin} />}
            />
            <Route
              path="/messages"
              element={isAuthenticated ? <Messages /> : <SignIn onLogin={handleLogin} />}
            />
            <Route
              path="/profile"
              element={isAuthenticated ? <Profile /> : <SignIn onLogin={handleLogin} />}
            />
            <Route
              path="/dashboard/event/:eventId"
              element={<EventDetail />} /* Updated Route */
            />
          </Routes>
          
          {/* NavBar is conditionally displayed only for authenticated users */}
          {isAuthenticated && <NavBar />}
        </>
      )}
    </Router>
  );
}

export default App;
