// src/dashboard/EventDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEventById } from '../api/eventService';

function EventDetail() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [popularUsers] = useState([
    { id: 1, name: 'SpongeBob', age: 24, profilePicture: 'https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/gangster-spongebob-chapman-dan.jpg', status: 'Recently active' },
    { id: 2, name: 'Bugz', age: 27, profilePicture: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/bf6c5b99-e99b-4043-8ebf-d8d327bc6650/dh9r05o-40dec46c-7c7b-4f23-8028-54718cc5eb71.jpg/v1/fill/w_720,h_720,q_75,strp/bugs_bunny_gangster_by_murilo1395moreno_dh9r05o-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzIwIiwicGF0aCI6IlwvZlwvYmY2YzViOTktZTk5Yi00MDQzLThlYmYtZDhkMzI3YmM2NjUwXC9kaDlyMDVvLTQwZGVjNDZjLTdjN2ItNGYyMy04MDI4LTU0NzE4Y2M1ZWI3MS5qcGciLCJ3aWR0aCI6Ijw9NzIwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.Bf13GX80LXtIkjE0YD967spJCqgVSd6NPQDCcLok0P4', status: 'Online' },
    { id: 3, name: 'FInn', age: 25, profilePicture: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/e9617ef7-6167-40e1-aab7-68a0da896491/dgmbnv5-38b1009f-58c9-4a04-8df8-72547a383dc5.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2U5NjE3ZWY3LTYxNjctNDBlMS1hYWI3LTY4YTBkYTg5NjQ5MVwvZGdtYm52NS0zOGIxMDA5Zi01OGM5LTRhMDQtOGRmOC03MjU0N2EzODNkYzUuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.dxeNuoyU4Fq8gA1BAC5IcUnwv2ZdlQw1tHa7TnpTz8c', status: 'Offline' },
  ]);

  useEffect(() => {
    async function fetchData() {
      try {
        const eventData = await getEventById(eventId);
        setEvent(eventData);
      } catch (err) {
        setError('Failed to load event details');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [eventId]);

  const handleEnrollToggle = () => {
    if (isEnrolled) {
      setModalMessage('You have been unenrolled from this event.');
    } else {
      setModalMessage('You are now enrolled in this event!');
    }
    setIsEnrolled(!isEnrolled);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openParticipantsModal = () => {
    setShowParticipantsModal(true);
  };

  const closeParticipantsModal = () => {
    setShowParticipantsModal(false);
  };

  if (loading) {
    return <p>Loading event details...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="p-6 bg-gradient-to-b from-white to-yellow-50 min-h-screen pb-32">
      <div className="max-w-3xl mx-auto">
        <div className="flex items-center justify-between mb-4">
          <button onClick={() => navigate(-1)} className="text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h1 className="text-lg font-semibold">Event</h1>
          <div className="w-6"></div>
        </div>

        {event ? (
          <>
            <img src={event.image} alt={event.title} className="w-full max-w-full h-auto object-cover rounded-md mb-6" />
            <h2 className="text-2xl font-bold mb-2">{event.title}</h2>
            <p className="text-gray-600 mb-4">{event.location}</p>
            <p className="mb-8">{event.description}</p>

            {/* Popular Users Section */}
            <h3 className="text-lg font-semibold mb-4">Popular users</h3>
            <ul className="mb-8">
              {popularUsers.map((user) => (
                <li key={user.id} className="flex items-center justify-between mb-4 border-b border-gray-300 pb-2">
                  <div className="flex items-center">
                    <img
                      src={user.profilePicture}
                      alt={user.name}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <p className="font-semibold">{user.name}, {user.age}</p>
                      <p className="text-gray-600 text-sm">{user.status}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            {/* Action Buttons */}
            <div className="flex-1 flex-col sm:flex-row sm:justify-between space-y-4 sm:space-y-0 sm:space-x-4">
              <button className={`flex-1 px-6 py-2 rounded-full w-full sm:w-auto ${isEnrolled ? 'bg-red-500 text-white' : 'bg-black text-white'}`} onClick={handleEnrollToggle}>
                {isEnrolled ? 'Unenroll' : 'Enroll'}
              </button>
              <button className="flex-1 px-6 py-2 border border-gray-300 rounded-full w-full sm:w-auto" onClick={openParticipantsModal}>
                See all participants
              </button>
            </div>

            {/* Modal for Enroll/Unenroll confirmation */}
            {showModal && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-md shadow-lg w-80 text-center relative">
                  <button onClick={closeModal} className="absolute top-2 right-2 text-gray-500 hover:text-black">
                    &times;
                  </button>
                  <div className="mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 mx-auto mb-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4" />
                    </svg>
                    <p className="text-lg font-bold">{modalMessage}</p>
                  </div>
                  <button onClick={closeModal} className="px-4 py-2 bg-gray-200 text-black rounded-full w-full">
                    Got it
                  </button>
                </div>
              </div>
            )}

            {/* Modal for Participants */}
            {showParticipantsModal && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
                  <h2 className="text-lg font-semibold mb-4">Participants</h2>
                  <ul>
                    {popularUsers.map((user) => (
                      <li key={user.id} className="flex items-center mb-2">
                        <img
                          src={user.profilePicture}
                          alt={user.name}
                          className="w-10 h-10 rounded-full mr-3"
                        />
                        <div>
                          <p className="font-semibold">{user.name}</p>
                          <p className="text-sm text-gray-600">{user.status}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <button onClick={closeParticipantsModal} className="mt-4 px-4 py-2 bg-gray-200 text-black rounded-full w-full">
                    Close
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <p>Event not found</p>
        )}
      </div>
    </div>
  );
}

export default EventDetail;
