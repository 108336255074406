// src/components/NavBar.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import navTrBlack from '../navtr.png'; // Black home icon
import navTrOrange from '../navtr_orange.png'; // Orange home icon

/**
 * NavBar component renders the main navigation bar at the bottom of the screen.
 * It includes links for Home, Messages, and Profile pages, each with active highlighting.
 */
function NavBar() {
  const [profileImage, setProfileImage] = useState(null);
  const userEmail = localStorage.getItem('userEmail'); // Adjust this based on your app's logic

  useEffect(() => {
    // Fetch the user's profile image from the backend
    if (userEmail) {
      fetch(`https://infost490f2408.soisweb.uwm.edu/getProfileImage.php?email=${encodeURIComponent(userEmail)}`)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setProfileImage(data.profileImage); // Set the profile image URL
          }
        })
        .catch(err => {
          console.error('Error fetching profile image:', err);
        });
    }
  }, [userEmail]);

  return (
    <div className="fixed bottom-0 left-0 w-full bg-white shadow-lg flex justify-around items-center py-3 z-50">
      
      {/* Home Button */}
      <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive ? 'text-orange-500' : 'text-gray-500'
        }
      >
        {({ isActive }) => (
          <div className="flex flex-col items-center">
            {/* Render the orange icon if active, otherwise the black icon */}
            <img src={isActive ? navTrOrange : navTrBlack} alt="Home" className="w-8 h-8" />
            <span className="text-sm">Home</span>
          </div>
        )}
      </NavLink>

      {/* Messages Button */}
      <NavLink
        to="/messages"
        className={({ isActive }) =>
          isActive ? 'text-orange-500' : 'text-gray-500'
        }
      >
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
            />
          </svg>
          <span className="text-sm">Messages</span>
        </div>
      </NavLink>

      {/* Profile Button with Profile Image */}
      <NavLink
        to="/profile"
        className={({ isActive }) =>
          isActive ? 'text-orange-500' : 'text-gray-500'
        }
      >
        <div className="flex flex-col items-center">
          {profileImage ? (
            <img
              src={`https://infost490f2408.soisweb.uwm.edu/${profileImage}`}
              alt="Profile"
              className="w-8 h-8 rounded-full mb-1 object-cover"
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-gray-300 mb-1" /> // Placeholder circle for no image
          )}
          <span className="text-sm">Profile</span>
        </div>
      </NavLink>
    </div>
  );
}

export default NavBar;
