// src/dashboard/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getEvents } from '../api/eventService';

function Dashboard() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getEvents()
      .then((data) => {
        setEvents(data);
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load events");
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading events...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="dashboard p-4 bg-gradient-to-b from-white to-yellow-50 pb-20 flex justify-center">
      {/* Centered and responsive container */}
      <div className="w-full max-w-md">
        {events.map((event) => (
          <Link to={`/dashboard/event/${event.id}`} key={event.id} className="block p-4 mb-6 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition bg-white">
            <img src={event.image} alt={event.title} className="w-full h-48 object-cover rounded-md mb-2" />
            <h3 className="text-lg font-semibold">{event.title}</h3>
            <p className="text-gray-600">{event.location}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
